@import "../../../index.scss";


.step {
  background: #ffffff;
  width: calc(100% - 2px - 40px);
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  border: 1px solid #e8e8e8;

  @media (min-width: 900px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1100px) {
    padding: 30px;
    width: calc(100% - 2px - 60px);
  }

  .step-header {
    img {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    span {
      font-family: VisueltPro-Medium;
      font-size: 15px;
      color: #a9a9a9;
      text-transform: uppercase;
    }
  }

  h2 {
    font-family: VisueltPro-Medium;
    font-size: 24px;
    color: #0a192e;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .step-content {
    p {
      font-family: VisueltPro-Regular;
      font-size: 16px;
      color: #545b61;
      line-height: 25px;
      margin: 6px auto;
      margin-bottom: 0;
    }
  }

  .edit {
    @extend .buttonBasic;
    width: fit-content;
    border: 0;
    font-size: 18px;
    padding: 18px 30px;
    margin-top: 19px;
    cursor: pointer;
  }

  .edit-done {
    padding: 0;
    background: none;
    border: 0;
    text-decoration: underline;
    color: #284e84;
    font-family: VisueltPro-Medium;
    margin-top: 10px;
    font-size: 16px;
  }
}

.step-disabled {
  background: #fafafa;
}
