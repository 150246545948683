@import "../../../index.scss";

.orderStatus {
  h3 {
    font-family: NewKansas-Medium;
    font-size: 25px;
    color: #0a192e;
    line-height: 32px;
    margin: 0;
    margin-bottom: 0;
  }

  img {
    margin: 60px auto;
    display: block;
  }

  .orderStatus-text {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    padding: 15px;
    position: relative;
    padding-top: 40px;
    padding-bottom: 10px;
    margin-top: 60px;
    margin-bottom: 60px;

    .orderStatus-status {
      background: #cbd3de;
      border-radius: 5px;
      padding: 10px 20px;
      width: fit-content;
      color: #243a58;
      font-family: VisueltPro-Medium;
      font-size: 17px;
      position: absolute;
      top: -20px;
    }

    .orderStatus-paragraph {
      font-family: VisueltPro-Regular;
      font-size: 17px;
      color: #000000;
      line-height: 25px;
      margin: 0;
      margin-bottom: 10px;
    }

    a {
      @extend .buttonBasic;
      margin-bottom: 10px;
      display: block;
      margin-top: 20px;
    }
  }

  .finished {
    .orderStatus-status {
      background: #b5daae;
      color: #1a4923;
    }
  }
}

