.tooltipWrapper {
  width: fit-content;
  position: relative;
}

.tooltip {
  max-width: 410px;
  width: calc(100vw - 50px);
  font-size: 16px;
  line-height: 25px;
  position: absolute;
  bottom: 35px;
  left: -10px;
  border-radius: 5px;
  color: #fff;
  background: #2A2A2A;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  margin: 0;
  list-style-position: outside;
}

.tooltipText {
  width: 100%;
  list-style: none;
  margin: 10px 0 !important;
  color: #fff !important;
}

.tooltipChildren {
  width: fit-content;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  color: #888888;
  border-bottom: 1px dotted gray;
  margin-top: 20px;
  margin-bottom: 40px;

  &:hover {
    cursor: pointer;
  }
}

.tooltip .tooltipContent::after {
  content: " ";
  position: absolute;
  top: calc(100% - 1px); /* At the bottom of the tooltip */
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-width: 15px 15px 0px;
  border-color: #2A2A2A transparent transparent transparent;
}
