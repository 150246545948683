.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 100;
  width: calc(100% - 30px);
  max-height: calc(100vh - 100px);
  overflow: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);

  @media (min-width: 540px) {
    max-width: calc(500px);
    left: calc((100% - 500px)/2);
    top: 30px;
    max-height: calc(100vh - 60px);
  }

  @media (min-width: 900px) {
    max-width: calc(500px);
    left: calc((100% - 500px)/2);
    top: 50px;
    max-height: calc(100vh - 100px);
  }
}

.modal-header {
  background: #CBD3DE;
  position: relative;
  margin-bottom: 30px;
  padding: 20px 0;

  h1 {
    font-family: VisueltPro-Medium !important;
    font-size: 20px !important;
    color: #243A58 !important;
    margin: 0 !important;
    margin-left: 20px !important;
    line-height: normal !important;
    width: calc(100% - 80px) !important;
  }

  button {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    background: 0;
    border: 0;
    padding: 0;
  }
}

.modal-content {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-bottom: 25px;

  .deleteText {
    margin: 30px 0;
  }

  .modal_save-delete {
    background: #FF6060;
    color: #fff;

    &:hover {
      background: #df4b4b;
    }
  }
}