.survey-button {
  background: rgb(58, 85, 124);
  border-radius: 5px;
  width: 100%;
  padding: 19px 0;
  // padding-bottom: 13px;
  display: block;
  margin: 0 auto;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: rgb(42, 65, 100);
  }

  b {
    font-family: VisueltPro-Medium;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
  }
}

.save-continue {
  background: rgb(58, 85, 124);
  border-radius: 5px;
  width: 100%;
  padding: 19px 0;
  // padding-bottom: 13px;
  display: block;
  margin: 0 auto;
  border: 0;
  text-decoration: none;
  text-align: center;
  font-family: VisueltPro-Medium;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: rgb(42, 65, 100);
  }
}

.save-continue-false {
  background: #323232;
  color: #a4a4a4;
  pointer-events: none;


}
