@import "../../index.scss";


.person_form {

  p {
    font-family: VisueltPro-Regular;
    font-size: 17px;
    color: #242627;
    line-height: 25px;
  }


  .modal_form {
    &:first-child {
      margin-top: 30px;
    }
  }
}

.modal_footer {
  margin-top: 30px;

  button {
    @extend .buttonBasic;
    border: 0;
    display: inline-block;
    margin-top: 0px;
    font-size: 17px;
    padding: 12px 20px;
    margin-right: 15px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .modal_cancel {
    background: 0;
    color: #000;
    border: 2px solid #D2D2D2;
    padding: 10px 18px;

    &:hover {
      background: #eeeeee;
    }
  }
}

.personBox {
  background: #fff;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  padding: 22px;
  margin-top: 20px;
  position: relative;

  h2 {
    font-family: VisueltPro-Medium;
    font-size: 20px;
    margin: 0;
  }

  p {
    font-family: VisueltPro-Regular;
    color: #5C5C5C;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  button {
    font-family: VisueltPro-Regular;
    font-size: 16px;
    color: #000000;
    text-align: center;
    color: #000;
    border-radius: 5px;
    border: 0;
    padding: 10px 15px;
    margin-right: 10px;
    cursor: pointer;
    background: rgb(239, 239, 239);
    display: inline-block !important;
  }

  .deletePerson {
    &:hover {
      background: #FF6060;
      color: #fff;
    }
  }

  .editPerson {
    &:hover {
      background: #3A557C;
      color: #fff;
    }
  }

  .personBox_buttons {
    input {
      position: absolute;
      top: calc((100% - 27px)/2) !important;
      right: 25px !important;
      width: fit-content;
      width: 27px !important;
      height: 27px !important;
    }

    .checkbox-label {
      input {
        opacity: 0;

        &:checked+div {
          background: #c2b391;
          border: 2px solid #c2b391;
          background-image: url("../../../src/Images/lil_check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 80%;
        }
      }

      .checkbox-label-box {
        position: absolute;
        top: calc((100% - 27px)/2);
        right: 25px;
        width: fit-content;
        width: 25px !important;
        height: 25px;
        cursor: pointer;
        background-color: #fff;
        border: 2px solid #dedbdd;
        border-radius: 5px;
      }
    }
  }
}