.alert {
  background: #ff6060;
  border-radius: 5px;
  padding: 15px 0;
  margin: 30px auto;

  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .alertText {
    font-family: VisueltPro-Medium;
    font-size: 16px;
    color: #ffffff !important;
    text-align: center;
    margin: 0 auto !important;
    width: calc(100% - 50px);
    line-height: 22px;
    background: none !important;
    padding: 0 !important;
  }
}

.info {
  background: #CBD3DE;
  border-radius: 5px;
  padding: 15px 0;
  margin: 30px auto;

  img {
    max-width: 2em;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .alertText {
    font-family: VisueltPro-Medium;
    font-size: 16px;
    color: #243A58 !important;
    text-align: center;
    margin: 0 auto !important;
    width: calc(100% - 50px);
    line-height: 22px;
  }
}
