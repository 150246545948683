@import "../../index.scss";


.wills {
  width: calc(100%);
  margin: 0 auto;
  margin-top: -40px;
  margin-bottom: -40px;
  background: #FBFBF9;
  padding-bottom: 50px;

  @media (min-width: 900px) {
    margin-top: -60px;
    margin-bottom: -60px;
    padding-bottom: 0;
    background: #fff;
  }

  .willsInner {
    width: calc(100% - 50px);
    margin: 0 auto;
    max-width: 600px;

    @media (min-width: 900px) {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 100px);
      max-width: 1150px;
    }
  }

  .left {
    @media (min-width: 900px) {
      margin-top: 70px;
      max-width: 600px;
      margin-bottom: 120px;
      width: calc(50% + 50px);
    }


    .bod {
      margin-top: 50px;
      margin-bottom: 50px;

      @media (min-width: 900px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      img {
        margin-bottom: 15px;

        @media (min-width: 900px) {
          margin-bottom: 0;
        }
      }

      div {
        @media (min-width: 900px) {
          width: calc(100% - 55px);
        }
      }
    }


    .topImg {
      width: 180px;
      height: 180px;
      display: block;
      margin-bottom: 35px;
      padding-top: 35px;

      @media (min-width: 900px) {
        display: none;
      }
    }

    h1 {
      margin-bottom: 25px;
      margin-top: 0;
      font-family: NewKansas-Medium;
      font-size: 32px;
      color: #22395e;

      @media (min-width: 900px) {
        margin-bottom: 35px;
      }
    }

    h2 {
      font-family: VisueltPro-Medium;
      font-size: 22px;
      color: #22395e;
      margin-top: 0;
      margin-bottom: 25px;
      line-height: 33px;

      @media (min-width: 900px) {
        margin-top: 0;
      }
    }

    p {
      font-family: VisueltPro-Regular;
      font-size: 16px;
      color: #42464d;
      line-height: 25px;
    }

    .perex {
      margin-bottom: 40px;

      @media (min-width: 900px) {
        margin-bottom: 60px;
      }

    }
  }

  .podporaBox {
    h2 {
      font-family: VisueltPro-Medium;
      font-size: 22px;
      color: #15243d;
      margin-top: 35px;
      margin-bottom: 20px;
      line-height: 33px;
    }

    .supportPic {
      width: 140px;
      display: block;
    }

    .text {
      margin-top: 15px;

      .allDay {
        .greenDot {
          background: #49B25D;
          height: 10px;
          width: 10px;
          border-radius: 100%;
          animation: pulse 2s infinite;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }

        span {
          font-family: VisueltPro-Medium;
          font-size: 16px;
          color: #49B25D;
          vertical-align: middle;
          line-height: 24px;
        }
      }

      .phone {
        margin-top: 10px;

        img {
          vertical-align: middle;
          margin-right: 10px;
        }

        a {
          font-family: VisueltPro-Regular;
          font-size: 16px;
          color: #000000;
          vertical-align: middle;
          text-decoration: none;

          span {
            font-family: VisueltPro-Bold;
          }
        }
      }
    }
  }

  .podporaBox2 {
    display: none;

    @media (min-width: 900px) {
      display: block;
    }
  }

  .podporaBox1 {
    @media (min-width: 900px) {
      display: none;
    }
  }

  .right {
    display: none;
    z-index: 1;
    margin-top: 70px;
    margin-bottom: 120px;


    @media (min-width: 900px) {
      display: block;
    }

    .mainImg {
      width: 324px;
      margin-left: auto;
      display: block;
      margin-bottom: 60px;

      @media (min-width: 900px) {
        width: 200px;
      }

      @media (min-width: 1250px) {
        width: 250px;
      }

      @media (min-width: 1400px) {

      }
    }

    .podporaBox {
      background: #FFFFFF;
      box-shadow: 0 5px 20px 0 rgba(0,0,0,0.14);
      border-radius: 15px;
      padding: 30px;

      .supportPic {
        @media (min-width: 1250px) {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .text {
        @media (min-width: 1250px) {
          display: inline-block;
          vertical-align: middle;
          margin-top: 0;
          margin-left: 25px;
        }
      }

      h2 {
        margin-top: 0;
      }
    }
  }

  .background {
    @media (max-width: 899px) {
      display: none;
    }

    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - 100px);
    height: 100%;
    z-index: 0;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }

  button {
    @extend .buttonBasic;
    padding-left: 100px;
    padding-right: 100px;

    @media (max-width: 899px) {
      margin-top: 50px;
    }
  }
}