.snackbar {
  visibility: hidden;
  width: 250px;
  margin-left: -150px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 25px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 30px;
}

.visible {
  visibility: visible;
  // -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s normal 6.1s;
}

@keyframes fadein {
  0% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    top: 30px;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}

// @-webkit-keyframes fadein {
//   from {
//     top: 0;
//     opacity: 0;
//   }
//   to {
//     top: 30px;
//     opacity: 1;
//   }
// }
// @-webkit-keyframes fadeout {
//   from {
//     top: 30px;
//     opacity: 1;
//   }
//   to {
//     top: 0;
//     opacity: 0;
//   }
// }
