.adminLogin {
  display: block;
  margin: 0 auto;
  width: calc(100% - 50px);
  max-width: 400px;

  h1 {
    margin-bottom: 30px;
  }
}


.adminMainScreen {

  .top {
    display: flex;
    max-width: 1150px;
    width: calc(100% - 50px);
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-between;


    @media (max-width: 899px) {
      display: block;
    }

    .left {
      max-width: calc(50% - 30px);
    }

    .right {
      max-width: calc(50% - 30px);
    }

    .sec {
      margin-bottom: 30px;

      h2 {
        font-family: VisueltPro-Medium;
        font-size: 24px;
        color: #000000;
        line-height: 32px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
      }

      a {
        font-family: VisueltPro-Medium;
        font-size: 24px;
        color: #000000;
        line-height: 32px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
      }

      span {
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
        opacity: 0.8;
      }
    }

    form {
      background-color: rgb(224, 218, 203);
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 30px;

      select {
        display: block;
        width: 100%;
        margin: 0 0;
        margin-bottom: 20px;
        border: 1px solid rgb(156, 156, 156);
        background: rgb(255, 255, 255);
        padding: 10px;
        border-radius: 5px;
      }

      input {
        display: block;
        width: calc(100% - 22px);
        background: rgb(255, 255, 255);
        padding: 10px;
        border: 1px solid rgb(156, 156, 156);
        border-radius: 5px;
      }

      button {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        border: 0;
        background: rgb(58, 85, 124);
        padding: 10px;
        border-radius: 5px;
        font-family: VisueltPro-Medium;
        font-size: 16px;
        color: #ffffff;
        line-height: 32px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
      }

      p {
        margin-bottom: 5px;
      }
    }
  }

  .bot {
    max-width: 1150px;
    width: calc(100% - 50px);
    margin: 0 auto;
    border-top: 2px solid rgb(68, 68, 68);
    padding-top: 30px;
    margin-top: 30px;

    section {
      margin-bottom: 30px;
      border-bottom: 1px dashed rgb(156, 156, 156);
      padding-bottom: 30px;

      h2 {
        font-family: VisueltPro-Medium;
        font-size: 24px;
        color: #000000;
        line-height: 32px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
      }

      h3 {
        font-family: VisueltPro-Medium;
        font-size: 18px;
        color: #000000;
        line-height: 32px;
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
        margin-top: 30px;
      }

      ul {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        margin: 10px auto;
        list-style-type: revert;

        li {
          margin: 7px 0;
          display: list-item;
          list-style: inherit;
          align-items: left !important;
          font-family: VisueltPro-Regular;
          font-size: 16px;
          line-height: 1.15;
          color: black;
          line-height: 28px;

          // &::marker {
          //   // unicode-bidi: isolate;
          //   // font-variant-numeric: tabular-nums;
          //   // text-transform: none;
          //   // text-indent: 0px !important;
          //   // text-align: start !important;
          //   // text-align-last: start !important;
          // }

          b {
            font-family: VisueltPro-Bold;
          }
        }
      }
    }
  }

}
