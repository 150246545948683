.summary {
  width: calc(100% - 50px);
  margin: 0 auto;
  max-width: 650px;

  @media (min-width: 900px) {
    display: flex;
    max-width: 1150px;
    width: calc(100% - 80px);
    justify-content: space-between;
    align-items: flex-start;
    margin-top: -20px;
  }

  @media (min-width: 1100px) {
    width: calc(100% - 100px);
  }

  .progress {
    @media (min-width: 900px) {
      width: calc(50% - 20px);
    }

    @media (min-width: 1100px) {
      width: calc(50% - 50px);
      max-width: 500px;
    }
  }

  .steps {
    margin-top: 40px;

    @media (min-width: 900px) {
      width: calc(50% - 20px);
      margin-top: 0;
    }

    @media (min-width: 1100px) {
      width: calc(50% - 50px);
      max-width: 500px;
    }

    .optional-h {
      font-family: VisueltPro-Medium;
      font-size: 20px;
      color: #000000;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  .progress-bar {
    margin-top: -10px;
    max-width: 400px;

    @media (min-width: 900px) {
      margin-top: 0;
    }

    span {
      font-family: VisueltPro-Medium;
      font-size: 17px;
      color: #49B25D;
    }

    svg {
      width: 100%;
      height: 10px;
      margin-top: 15px;
    }
  }

  .main-img {
    // width: 100%;
    margin-top: 30px;
    margin-bottom: 0;
    max-width: 400px;
    max-height: 220px;

    @media (min-width: 900px) {
      // width: 400px;
      margin-top: 50px;
    }
  }

  .progress-text {
    h1 {
      font-family: NewKansas-Medium;
      font-size: 26px;
      color: #0a192e;
      line-height: 35px;
      margin: 0 auto;
      margin-top: 20px;

      @media (min-width: 900px) {
        margin-top: 30px;
        line-height: 43px;
        font-size: 32px;
      }
    }

    p {
      font-family: VisueltPro-Regular;
      font-size: 17px;
      color: #545b61;
      line-height: 25px;
      margin-top: 25px;
      margin-bottom: 35px;

      @media (min-width: 900px) {
        margin-bottom: 35px;
      }

      b {
        font-family: VisueltPro-Medium;
        color: #14202c;
      }
    }

    .logout-button {
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      display: block;
      font-family: VisueltPro-Medium;
      font-size: 18px;
      color: #000000;
      text-align: center;
      width: 100%;
      padding: 18px 0;
      margin-top: 15px;
      margin-bottom: 20px;
    }

    .hotline {
      margin: 0 auto;
      display: block;
      width: fit-content;

      img {
        width: 19px;
        height: 19px;
        vertical-align: middle;
        margin-right: 14px;
      }

      span {
        font-family: VisueltPro-Regular;
        font-size: 17px;
        color: #000000;
        vertical-align: middle;
        bottom: -1.5px;
        position: relative;
      }

      b {
        font-family: VisueltPro-Bold;
      }
    }

    .skipGifts {
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      display: block;
      font-family: VisueltPro-Medium;
      font-size: 18px;
      color: #000000;
      text-align: center;
      width: 100%;
      padding: 13px 0;
      padding-bottom: 12px;
      margin-top: 15px;
      margin-bottom: 15px;
      text-decoration: none;
    }
  }

  .invite-partner {
    background-image: linear-gradient(198deg, #D4EFCF 0%, #BEE3B6 100%);
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;

    @media (min-width: 900px) {
      margin-top: 40px;
    }

    h3 {
      font-family: VisueltPro-Bold;
      font-size: 18px;
      color: #1a4923;
      margin: 0 auto;
    }

    p {
      font-family: VisueltPro-Regular;
      font-size: 16px;
      color: #265B30;
      margin: 10px auto;
    }

    button {
      outline: none;
      background-color: transparent;
      border: none;
      font-family: VisueltPro-Medium;
      font-size: 16px;
      color: #265B30;
      margin: 0 auto;
      text-decoration: underline;
    }
  }

  .prepaid-bubble {
    background-image: linear-gradient(198deg, #f1ebdb 0%, #e4d7b6 100%);
    margin-top: 20px;

    h3 {
      color: #413515;
    }

    p {
      color: #62583d;
    }

    button {
      color: #413515;
    }
  }

  .annoucement {
    background: #cbd3de;
    margin-top: 15px;
    
    h3 {
      color: #243a58;
    }

    p {
      color: #243a58;
    }
  }
}

.settingsStep {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  width: calc(100% - 2px - 40px);
  padding: 20px;
  margin-bottom: 20px;
  position: relative;

  h2 {
    font-family: VisueltPro-Medium;
    font-size: 18px;
    color: black;
    margin: 0;
  }

  a {
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
    text-decoration: underline;
    color: #a7a7a7;
    font-family: VisueltPro-Medium;
    margin-top: 10px;
    font-size: 16px;
    display: block;
  }
}
