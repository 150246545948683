@import "../../../index.scss";


.payment_page {
  display: flex;
  list-style: none;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1150px;
  margin: 0 auto;
  width: calc(100% - 50px);
  padding-bottom: 30px;

  @media (max-width: 900px) {
    width: calc(100%);
    display: block;
    margin-top: -40px;
  }

  .payment-left {
    max-width: calc(475px);
    width: calc(45%);

    @media (max-width: 900px) {
      max-width: none;
      width: calc(100%);
    }

    .summaryBox {
      background: #ffffff;
      border-radius: 5px;
      width: calc(100% - 60px);
      padding: 30px;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      border: 1px solid #e8e8e8;

      @media (max-width: 900px) {
        border: 0;
        width: calc(100% - 50px);
        padding: 30px 25px;
        margin: 0 auto;
        border-radius: 0;
      }

      h1 {
        font-family: VisueltPro-Medium;
        font-size: 22px;
        color: #000000;
        margin: 0;

        @media (max-width: 900px) {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      ul {
        padding: 10px 0;
        border-top: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;
        margin: 20px auto;

        @media (max-width: 900px) {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        li {
          display: flex;
          list-style: none;
          align-items: center;
          margin-bottom: 15px;
          margin-top: 15px;

          p {
            font-family: VisueltPro-Regular;
            font-size: 16px;
            color: #36434f;
            line-height: 25px;
            margin-left: 25px;

            a {
              color: #36434f;
              text-decoration: underline;
            }
          }
        }
      }

      .payment_item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 5px;

        @media (max-width: 900px) {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        span {
          font-family: VisueltPro-Regular;
          font-size: 16px;
          color: #000000;
          line-height: 25px;

          &:last-child {
            font-family: VisueltPro-Medium;
            font-size: 16px;
            color: #000000;
            text-align: right;
            line-height: 25px;
          }
        }
      }

      .payment_item-2 {
        border-bottom: 1px solid #d2d2d2;
        padding-bottom: 15px;
        margin-bottom: 10px;
      }

      .payment_discount-code {
        @media (max-width: 900px) {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        p {
          background: #3A557C;
          color: #fff;
          width: fit-content;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: 30px;
        }

        .payment_discount-code-inner {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          background: #ffffff;
          border-radius: 5px;
          overflow: hidden;
          margin-top: 20px;

          input {
            font-family: VisueltPro-Regular;
            border: 2px solid #d2d2d2;
            font-size: 16px;
            color: #000;
            line-height: 25px;
            border-right: 0;
            width: 100%;
            padding: 12px;
            padding-left: 15px;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
          }

          button {
            border: 2px solid #CBD3DE;
            background: #CBD3DE;
            font-family: VisueltPro-Medium;
            font-size: 16px;
            color: #243A58;
            text-align: center;
            line-height: 25px;
            padding: 12px;

            &:hover {
              background: rgb(181, 196, 217);
              border: 2px solid rgb(181, 196, 217);
            }
          }
        }
      }
    }

    .reviewBox {
      background: #ffffff;
      width: calc(100% - 60px);
      padding: 30px;
      margin-top: 40px;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      border: 1px solid #e8e8e8;

      @media (max-width: 900px) {
        display: none;
      }

      .person {
        display: flex;
        align-items: center;

        img {
          width: 50px;
          margin-right: 20px;
        }

        div {
          span {
            font-family: VisueltPro-Bold;
            font-size: 16px;
            color: #000000;
            line-height: 25px;
            display: block;
          }

          img {
            width: 90px;
            margin-top: 5px;
            display: block;
          }
        }
      }

      .text {
        font-family: VisueltPro-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 25px;
        margin-top: 30px;
      }
    }
  }

  .payment-right {
    width: calc(55% - 50px);
    max-width: 575px;

    @media (max-width: 900px) {
      max-width: 600px;
      width: calc(100% - 50px);
      margin: 0 auto;
      margin-top: 60px;
    }

    h2 {
      font-family: VisueltPro-Medium;
      font-size: 22px;
      color: #000000;
      margin: 0;
    }

    .karta {
      margin-top: 30px;

      .heading {
        h2 {
          vertical-align: middle;
          display: inline-block;
          margin-right: 20px;
        }

        div {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: -3px;

          @media (max-width: 900px) {
            display: block;
            margin-top: 20px;
          }

          img {
            height: 24px;
            margin-right: 15px;
          }
        }
      }

      .payment_confirmation {
        .button {
          @extend .buttonBasic;
          display: block;
          width: calc(100% - 50px);
          max-width: 240px;
          margin-right: auto;
          margin-top: 30px;
        }

        p {
          font-family: VisueltPro-Regular;
          font-size: 16px;
          color: #6e6e6e;
          margin-top: 15px;

          a  {
            vertical-align: middle;
          }

          img {
            vertical-align: middle;
            margin-top: -3px;
            margin-right: 12px;
          }
        }
      }
    }

    hr {
      border: 1px solid #d8d8d8;
      margin: 50px auto;
      border-bottom: 0;
    }

    .ucet {
      .ucetInner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media (max-width: 500px) {
          display: block;
        }

        div {
          font-family: VisueltPro-Regular;
          font-size: 16px;
          color: #000000;
          line-height: 25px;
          width: calc(100% - 190px);

          @media (max-width: 500px) {
            width: calc(100%);
            margin-bottom: 30px;
          }

          span {
            font-family: VisueltPro-Bold;
          }

          p {
            font-family: VisueltPro-Regular;
            font-size: 16px;
            color: #000000;
            line-height: 25px;
            margin-bottom: 0;
            margin-top: 30px;

            a {
              color: #000000;
            }
          }
        }

        img {
          width: 165px;
        }
        .spinner {
          border: 8px solid #f3f3f3; /* Light grey */
          border-top: 8px solid #ffee62;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .support {
      display: flex;
      align-items: center;

      @media (max-width: 500px) {
        display: block;
      }

      .suppText {
        margin-top: -3px;
        margin-left: 25px;

        @media (max-width: 500px) {
          margin-left: 0;
          margin-top: 15px;
        }

        .time {
          margin-bottom: 10px;

          .greenDot {
            background: rgb(73, 178, 93);
            height: 10px;
            width: 10px;
            border-radius: 100%;
            animation: 2s ease 0s infinite normal none running pulse;
            display: inline-block;
            vertical-align: middle;
          }

          @keyframes pulse {
            0% {
              opacity: 1;
            }

            50% {
              opacity: 0.25;
            }

            100% {
              opacity: 1;
            }
          }

          span {
            font-family: VisueltPro-Medium;
            font-size: 16px;
            color: #00b451;
            line-height: 24px;
            vertical-align: middle;
            margin-left: 10px;
          }
        }

        .phone {
          img {
            vertical-align: middle;
            margin-right: 10px;
          }

          span {
            vertical-align: middle;
            margin-bottom: -2px;

            span {
              font-family: VisueltPro-Medium;
            }
          }
        }
      }

      .supportImg {
        width: 140px;
      }
    }
  }
}

.invite_friends {
  border-radius: 5px;
  background: #ffffff;
  border: 2px solid #7ab284;
  border-radius: 5px;
  padding: 15px;
  margin-top: 40px;

  h4 {
    font-family: VisueltPro-Medium;
    font-size: 18px;
    text-align: left;
    width: 100%;
    margin: 0;
  }

  p {
    font-family: VisueltPro-Regular;
    font-size: 16px;
    text-align: left;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ref_code-generated {
    text-align: left;
    width: calc(100% - 20px);
    background-image: linear-gradient(198deg, #D4EFCF 0%, #BEE3B6 100%);
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;

    p {
      font-family: VisueltPro-Regular;
      font-size: 16px;
      color: #1a4923;
      margin: 0;
    }

    b {
      font-family: VisueltPro-Medium;
      font-size: 20px;
      color: #1a4923;
      margin-top: 10px;
      display: block;
    }
  }

  .save-continue {
    background: none;
    color: rgb(58, 85, 124);
    border-radius: 5px;
    font-size: 16px;
    text-align: left;
    text-decoration: underline;
    font-family: VisueltPro-Regular;
    padding: 0;
  }
}

.payment-processing {
  width: calc(100% - 50px);
  margin: 0 auto;
  max-width: 450px;

  .askForRecheck {
    margin-top: -60px;
    display: flex;
    justify-content: flex-end;
    .edit-done {
      padding: 0;
      background: none;
      border: 0;
      text-decoration: underline;
      color: rgb(58, 85, 124);
      font-family: VisueltPro-Medium;
      margin-top: 10px;
      font-size: 16px;
    }
  }
}
