.input_section-date {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 20px;
  max-width: 500px;

  input {
    width: calc(100% / 5 * 1.5 - 40px) !important;
  
    &:last-child {
      width: calc(100% / 5 * 2 - 40px) !important;
    }
  }
}


