.header-wrapper {
  background: #FFFFFF;
  border-bottom: 1px solid #DFDFDF;
  width: 100%;
  position: relative;

  nav {
    width: 100%;
    margin: 0 auto;
    max-width: 1150px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 0;
    max-width: 1150px;
    width: calc(100% - 100px);

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    .logout_button {
      display: none;
      font-family: VisueltPro-Medium;
      font-size: 16px;
      background: rgb(203, 211, 222);
      color: rgb(36, 58, 88);
      border-radius: 5px;
      padding: 10px 20px;
      cursor: pointer;
      border: 0;
      display: block;

      &:hover {
        background: rgb(181, 196, 217);
      }

      @media (max-width: 899px) {
        display: none;
      }
    }

    .logout_button-logged {
      display: none;
    }

    .left {
      img {
        display: block;
      }
    }
  
    .right {
      display: flex;
      justify-content: center;
      align-items: center;

      .available {

        @media (max-width: 560px) {
          display: none;
        }

        .blinking_dot {
          background: #49B25D;
          height: 10px;
          width: 10px;
          border-radius: 100%;
          animation: pulse 2s infinite;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }

        span {
          font-family: VisueltPro-Medium;
          font-size: 18px;
          color: #243A58;
        }
      }

      .customer_support {
        height: 32px;
        margin: 0 20px;
        display: block;

        @media (max-width: 380px) {
          display: none;
        }
      }
  
      a {
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
          display: block;
          margin-right: 10px;
        }
  
        span {
          display: inline-block;
          font-family: VisueltPro-Bold;
          font-size: 16px;
          color: #243A58;
        }
      }
    }

    .logout_button-right-logged {
      @media (min-width: 900px) {
        right: 0;
      }
    }
  }
}