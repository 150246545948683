.form {
  width: calc(100% - 50px);
  margin: 0 auto;
  max-width: 650px;

  .form-title {
    font-family: NewKansas-Medium;
    font-size: 25px;
    color: #0a1c33;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .input_section {
    margin-bottom: 40px;

    .input_section-title{
      font-family: NewKansas-Medium;
      font-size: 25px;
      color: #0a1c33;
      line-height: 32px;
      margin: 0;
      margin-bottom: 25px;
    }

    .input_section-description {
      font-family: VisueltPro-Regular;
      font-size: 17px;
      color: #5C5C5C;
      line-height: 25px;
      margin: 0;
      margin-bottom: 25px;
    }

    .input_section-label {
      font-family: VisueltPro-Medium;
      margin-bottom: 10px;
      font-size: 16px;
      display: block;

      @media (min-width: 900px) {
        margin-top: -10px;
      }
    }

    .input_section-children {
      input {
        font-family: VisueltPro-Regular;
        font-size: 18px;
        color: #000000;
        background: #FFFFFF;
        border: 1px solid #D2D2D2;
        border-radius: 5px;
        padding: 14px 15px;
        padding-bottom: 13px;
        width: calc(100% - 2px - 30px);

        @media (min-width: 900px) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .sex-selection {
    .children0{
      margin-top: 25px;
    }
  }

  .radio-label {
    margin-bottom: 15px;
    font-family: VisueltPro-Regular;
    font-size: 18px;
    color: #000000;
    display: flex;
    align-items: center;
    cursor: pointer;
    touch-action: manipulation;

    input {
      position: absolute;
      width: 30px;
      max-width: 30px;
      height: 30px;
      opacity: 0;
      cursor: pointer;

      &:checked+div {
        &:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          width: 20px;
          height: 20px;
          background: #eab23d;
          border-radius: 50%;
          zoom: 1;
        }
      }
    }

    .radio-checkbox {
      display: flex;
      position: relative;
      width: 30px;
      max-width: 30px;
      height: 30px;
      flex: 0 0 30px;
      margin-right: 16px;
      border: 2px solid #dedbdd;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
    }
  }

  .buttons_section {
    background: none;
    border: none;
    border-radius: 0;
    margin-top: 0;
  }

  .single_button_section {
    width: 100%;
    background: #FFFFFF;
    color: #000;
    border: 2px solid #D2D2D2;
    border-radius: 5px;
    padding: 15px 0;
    margin-top: 10px;
    font-family: VisueltPro-Medium;
    font-size: 18px;
    color: #000000;
    text-align: center;
  }

  .pressed {
    background: #c2b391;
    color: #FFFFFF;
    border: 0;
    padding: 16px 0;
  }

  .select {
    width: 100%;
    padding: 15px;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOSAyMy44Ij48cGF0aCBmaWxsPSIjOTc5Nzk3IiBkPSJNOS41LjJMLjMgMTBIMTl6TTkuNyAyMy44TDE5IDE0SC4zeiIvPjwvc3ZnPg==") no-repeat 100%;
    background-size: 12px;
    background-position: 95%;
    background-color: #fff;
    appearance: none;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
  }
}

.assets_property_page {
  .partner_add {
    margin-bottom: 10px;
  }

  form {
    margin-top: 30px;
  }

  .modal_form-radio {
    .input_section-label {
      margin-bottom: 20px;
    }
  }

  .modal_form-ownership {
    .radio-label {
      font-size: 17px;
      line-height: 25px;
    }
  }
}

