@import "../../../index.scss";


.notFound {
  max-width: 600px;
  margin-top: 100px;
  height: calc(100vh - 400px);
  min-height: calc(400px);
  margin-bottom: 0px;
  width: calc(100% - 50px);
  margin: 0 auto;

  h1 {
    font-family: NewKansas-Medium;
    font-size: 32px;
    color: #293A56;
  }

  p {
    font-family: VisueltPro-Regular;
    font-size: 17px;
    color: #394B5C;
    line-height: 28px;
    margin-top: 30px;
    
    a {
      color: #394B5C;
    }
  }

  div {
    a {
      @extend .buttonBasic;
      margin: 0;
      margin-top: 40px;
    }
  }
}