.introduction {
  h1 {
    font-family: VisueltPro-Medium;
    font-size: 24px;
    color: #000000;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .divBlock {
    display: flex;
    margin-top: 30px;
  }

  h3 {
    font-family: VisueltPro-Medium;
    font-size: 20px;
    color: #000000;
    display: inline-block;
    vertical-align: middle;
  }

  h4 {
    font-family: VisueltPro-Medium;
    font-size: 20px;
    color: #000000;
    margin-top: 00px;
    margin-bottom: 10px;
  }

  p {
    font-family: VisueltPro-Regular;
    font-size: 17px;
    color: #666666;
    line-height: 25px;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    margin-right: 13px;
  }

  ol {
    padding: 0;
    padding-left: 22px;
    margin: 0;
    margin-top: 20px;

    li {
      font-family: VisueltPro-Regular;
      font-size: 17px;
      color: #666666;
      line-height: 25px;
      list-style-type: disc;
      margin: 10px 0;
    }
  }

  .save_continue {
    margin-top: 50px;
    max-width: 250px;
    margin-left: 0;
  }
}

.charities-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;

  .charities-list-left {
    width: calc((100% - 25px) / 2);
  }

  .charities-list-right {
    width: calc((100% - 25px) / 2);
  }

  .charities-box {
    width: calc(100% - 2px);
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    height: 140px;
    margin-bottom: 25px;
    text-align: center;
    cursor: pointer;

    img {
      max-width: calc(100% - 30px);
      max-height: calc(100% - 30px);
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }

    .helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  .selected {
    width: calc(100% - 8px);
    background: #FFFFFF;
    border: 4px solid #c2b391;
    height: 134px;
    border-radius: 5px;

    img {
      max-width: calc(100% - 30px + 6px);
      max-height: calc(100% - 30px + 6px);
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
  }
}

.charities-form {
  .partner_add {
    background: #CBD3DE;
    color: #243A58;
    border: 0;
    padding: 16px 0;
    width: 100%;
    margin-top: 0;

    &:hover {
      background: rgb(181, 196, 217);
    }
  }

  .personBox {
    margin-top: 0;
    margin-bottom: 25px;
    border: 4px solid #c2b391;
  }
}

.estate {
  .share-right {
    .share-sum {
      text-align: center;
      margin-bottom: 20px;
      background: rgba(0, 0, 0, 0.1);;
      padding: 15px 0;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
      border-radius: 5px;
      margin-bottom: 35px;
      display: block;
      margin-left: 0;

      span {
        font-family: VisueltPro-Regular;
        font-size: 17px;
        color: black;
        text-align: center;
        line-height: 23px;

        b {
          font-family: VisueltPro-Bold;
        }
      }
    }

    .share-sum-100 {
      background: #68ac5b;
      
      span {
        color: #fff;
      }
    }
  }

  .personBox {
    position: relative;
    display: flex;
    justify-content: space-between;

    .personBox_txt {
      width: calc(100% - 115px);

      p {
        margin-bottom: 0;
      }
  
      h2 {
        overflow-wrap: anywhere;
      }
    }
  }

  .personBox_buttons {
    position: static;
    top: 20px;
    right: 20px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      input {
        display: inline-block;
        position: static;
        width: 50px !important;
        text-align: center;
        margin-right: 6px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      input[type=number] {
        -moz-appearance:textfield; /* Firefox */
      }

      span {
        display: inline-block;
        font-family: VisueltPro-Medium;
        font-size: 20px;
        color: #000000;
      }
    }

    .checkbox-label {
      input {
        right: 25px;
        top: 25px !important;
      }

      .checkbox-label-box {
        right: 25px;
        top: 25px;
      }
    }

    .checkbox-label-2 {
      input {
        right: 12px;
        top: 12px !important;
      }

      .checkbox-label-box {
        right: 12px;
        top: 12px;
      }
    }
  }
}

.estate-backup-form {
  .input_section-children {
    .personBox_buttons {
      input {
        top: 15px;
        right: 2px;
      }
    }
  }
}

.charities-form {
  .personBox {
    display: block;

    .personBox_buttons {
      margin-top: 20px;
    }
  }
}

.estate-people {
  .personBox {
    display: block;
  }

  .personBox_buttons {
    position: static;

    button {
      display: block;
      margin-top: 15px;
    }

    input {
      position: absolute;
      top: 22px;
      right: 22px;
    }
  }

  .checkbox-label-2 {
    input {
      right: 25px !important;
      top: 25px !important;
    }

    .checkbox-label-box {
      right: 25px !important;
      top: 25px !important;
    }
  }
}