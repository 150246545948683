@import "../../../index.scss";


.registerSuccess {
  width: calc(100% - 50px);
  max-width: 400px;
  margin: 0 auto;

  h2 {
    font-family: NewKansas-Medium;
    font-size: 28px;
    color: #293A56;
    margin: 0;
    margin-bottom: 30px;
  }

  p {
    font-family: VisueltPro-Regular;
    font-size: 17px;
    color: #5C5C5C;
    margin: 0;
    line-height: 25px;
  }

  a {
    @extend .buttonBasic;
    margin-top: 40px;
    display: block;
  }

  button {
    @extend .buttonBasic;
    margin-top: 40px;
    display: block;
  }
}

.confirm-success {
  font-size: 1.5em;
  width: 10em;
  color: #1ba300;
  padding: 0.3em 0.7em;
  background-color: #fefefe;
  border-radius: 0.2em;
}
