/*****************************************

fonts

*****************************************/

@font-face {
  font-family: "VisueltPro-Black";
  src: url("assets/fonts/VisueltPro-Black.ttf");
}

@font-face {
  font-family: "VisueltPro-BlackItalic";
  src: url("assets/fonts/VisueltPro-BlackItalic.ttf");
}

@font-face {
  font-family: "VisueltPro-Bold";
  src: url("assets/fonts/VisueltPro-Bold.ttf");
}

@font-face {
  font-family: "VisueltPro-BoldItalic";
  src: url("assets/fonts/VisueltPro-BoldItalic.ttf");
}

@font-face {
  font-family: "VisueltPro-Italic";
  src: url("assets/fonts/VisueltPro-Italic.ttf");
}

@font-face {
  font-family: "VisueltPro-Light";
  src: url("assets/fonts/VisueltPro-Light.ttf");
}

@font-face {
  font-family: "VisueltPro-LightItalic";
  src: url("assets/fonts/VisueltPro-LightItalic.ttf");
}

@font-face {
  font-family: "VisueltPro-Medium";
  src: url("assets/fonts/VisueltPro-Medium.ttf");
}

@font-face {
  font-family: "VisueltPro-MediumItalic";
  src: url("assets/fonts/VisueltPro-MediumItalic.ttf");
}

@font-face {
  font-family: "VisueltPro-Regular";
  src: url("assets/fonts/VisueltPro-Regular.ttf");
}

@font-face {
  font-family: "NewYorkMedium-Black";
  src: url("assets/fonts/NewYorkMedium-Black.ttf");
}

@font-face {
  font-family: "NewYorkMedium-BlackItalic";
  src: url("assets/fonts/NewYorkMedium-BlackItalic.ttf");
}
@font-face {
  font-family: "NewYorkMedium-Bold";
  src: url("assets/fonts/NewYorkMedium-Bold.ttf");
}

@font-face {
  font-family: "NewYorkMedium-BoldItalic";
  src: url("assets/fonts/NewYorkMedium-BoldItalic.ttf");
}

@font-face {
  font-family: "NewYorkMedium-Medium";
  src: url("assets/fonts/NewYorkMedium-Medium.ttf");
}

@font-face {
  font-family: "NewYorkMedium-MediumItalic";
  src: url("assets/fonts/NewYorkMedium-MediumItalic.ttf");
}

@font-face {
  font-family: "NewYorkMedium-Regular";
  src: url("assets/fonts/NewYorkMedium-Regular.ttf");
}

@font-face {
  font-family: "NewYorkMedium-RegularItalic";
  src: url("assets/fonts/NewYorkMedium-RegularItalic.ttf");
}

@font-face {
  font-family: "NewYorkMedium-Semibold";
  src: url("assets/fonts/NewYorkMedium-Semibold.ttf");
}

@font-face {
  font-family: "NewYorkMedium-SemiboldItalic";
  src: url("assets/fonts/NewYorkMedium-SemiboldItalic.ttf");
}

@font-face {
  font-family: "NewKansas-Medium";
  src: url("assets/fonts/NewKansas-Medium.ttf");
}

/*****************************************

colors

*****************************************/

$yellow: rgb(255, 238, 98);

/*****************************************

defaults

*****************************************/

* {
  outline: none;
  font-family: VisueltPro-Regular, Helvetica, sans-serif;
}
html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
p,
span,
a,
b,
ul,
li,
button,
label {
  font-weight: normal !important;
  margin: 0;
  padding: 0;
  font-weight: 400!important;
  -webkit-font-smoothing: antialiased!important;
  outline: none;
}

h2 {
  font-family: NewKansas-Medium, Georgia, serif;
}

img {
  max-width: 100vw;
}

body {
  background: #fafaf9;
  background-image: linear-gradient(68deg, #EBEEF3 0%, #FAFAF9 15%, #FAFAF9 79%, #FBF5EA 100%);
}

button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="string"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  cursor: pointer;
}


.buttonBasic  {
  border: none;
  text-align: center;
  font-family: VisueltPro-Medium;
  font-size: 18px;
  color: #FFFFFF;
  background: rgb(58, 85, 124);
  border-radius: 5px;
  padding: 19px 30px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;

  &:hover {
    background: rgb(42, 65, 100);
  }

  @media (max-width: 400px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}