.loading-screen {
  height: calc(100vh - 61px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  position: absolute;
  top: 0;
  right: 0;
  background: #FAFAFA;
}