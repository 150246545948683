.partner-modal-content {
  form {
    margin-top: 30px;
  }

  .ref_code-title {
    font-family: VisueltPro-Bold;
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    margin-bottom: 15px;
  }

  .ref_code-text {
    font-family: VisueltPro-Regular;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 10px;
    color: #242627;
  }

  .partner_code-form {
    label {
      font-family: VisueltPro-Medium;
      font-size: 16px;
      color: #000;
    }

    .input_section-input {
      font-family: VisueltPro-Regular;
      font-size: 17px;
      color: #000;
      background: #FFFFFF;
      border: 1px solid #D2D2D2;
      border-radius: 5px;
      width: calc(100% - 32px);
      padding: 12px 15px;
      margin-top: 10px;
      margin-bottom: 0px;
    }

    .save-continue {
      margin-top: 20px;
    }
  }

  .ref_code-generated {
    background: #FFEABF;
    border-radius: 5px;
    padding: 20px;
    margin-top: 30px;

    p {
      margin: 0;
      margin-bottom: 15px;
    }

    b {
      font-size: 30px;
      font-family: VisueltPro-Medium;
    }
  }
}


.modal_form-charity {
  // padding-top: 25px;
  margin-bottom: 0px !important;
}