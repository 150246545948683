.form-gifts {
  .gifts-heading {
    font-family: VisueltPro-Medium;
    font-size: 22px;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .gift_container {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    padding: 22px;
    margin-bottom: 20px;

    .gift_icon {
      margin-bottom: 12px;
    }

    .gift_text {
      h4 {
        font-family: VisueltPro-Medium;
        font-size: 20px;
        margin: 0;
      }

      p {
        font-family: VisueltPro-Regular;
        color: #5C5C5C;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .gift_buttons {
      button {
        font-family: VisueltPro-Regular;
        font-size: 16px;
        color: #000000;
        text-align: center;
        color: #000;
        border-radius: 5px;
        border: 0;
        padding: 10px 15px;
        margin-right: 10px;
        cursor: pointer;
        background: rgb(239, 239, 239);

        &:hover {
          background: #3A557C;
          color: #fff;
        }
      }

      .gift_button-remove {
        &:hover {
          background: #FF6060;
          color: #fff;
        }
      }
    }
  }
}

.form-gift_type {
  h3 {
    font-family: VisueltPro-Medium;
    font-size: 20px;
    margin: 0;
    margin-top: 40px;
  }

  .personBox {
    p {
      margin-bottom: 0;
    }
  }

  textarea {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    width: calc(100% - 30px);
    resize: none;
    padding: 15px;
    width: calc(100% - 2px - 30px);
    font-family: VisueltPro-Regular;
    font-size: 17px;
    height: 200px;
    line-height: 25px;
    margin-top: 10px;
  }

  .charities-list {
    margin-top: 20px;
  }

  .partner_add {
    margin-bottom: 50px;
  }
}