.goBack {
  margin-top: 0 !important;

  button {
    background: none;
    padding: 0;
    display: block;
    margin-bottom: 30px;
    border: 0;
    font-family: VisueltPro-Regular;
    font-size: 16px;
    color: #939393;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
}
