.footer-wrapper {
  background: #243A58;
  position: relative;

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 100px);
    margin: 0 auto;
    margin: 0 auto;
    max-width: 1150px;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    @media (max-width: 520px) {
      display: block;
    }

    a {
      color: #fff;
      opacity: 0.6;
      vertical-align: middle;
      font-family: VisueltPro-Regular;
      font-size: 14px;
      color: #FFFFFF;
      text-align: right;
      text-decoration: none;

      @media (max-width: 520px) {
        text-align: left;
        margin-top: 10px;
        display: block;
      }

      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }

    p {
      font-family: VisueltPro-Regular;
      font-size: 14px;
      color: #FFFFFF;
      margin: 0;
      opacity: 0.6;
    }
  }
}