.buttons_section {
  background: #fff;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 40px;

  button {
    background: none;
    border: 0;
    font-family: VisueltPro-Regular;
    font-size: 17px;
    color: #000;
    padding: 22px 22px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    background: rgb(239, 239, 239);

    &:hover {
      background: #f1ede7;
    }
  }

  .pressed {
    background: #c2b391;
    color: #fff;
    border: 2px solid #c2b391 !important;
    padding: 15px 0 !important;

    &:hover {
      background: #c2b391;
    }
  }
}

.partner_add {
  font-family: VisueltPro-Medium;
  font-size: 18px;
  color: #000000;
  text-align: center;
  background: #CBD3DE;
  color: #243A58;
  border-radius: 5px;
  border: 0;
  padding: 15px 30px;
  display: block;
  margin-top: 25px;

  &:hover {
    background: rgb(181, 196, 217);;
  }
}