.settingsPage {
  width: calc(100% - 50px);
  margin: 0 auto;
  max-width: 650px;

  .backToHomepage {
    font-family: VisueltPro-Regular;
    font-size: 16px;
    color: #939393;
    text-align: left;
    margin-bottom: 40px;
    display: block;
  }

  h1 {
    margin: 0;
    font-family: NewKansas-Medium;
    font-size: 32px;
    color: #243A58;
  }

  .settingsPage-section {
    margin-top: 40px;

    h2 {
      font-family: VisueltPro-Regular;
      font-size: 20px;
      margin-bottom: 20px;
    }

    button {
      border-radius: 5px;
      width: fit-content;
      padding: 14px 25px;
      padding-bottom: 13px;
      display: block;
      margin: 0 auto;
      margin-left: 0;
      border: 0;
      text-decoration: none;
      text-align: center;
      font-family: VisueltPro-Medium;
      font-size: 18px;
      color: #000000;
      text-align: center;
    }
  }

  .settingsPage-section-delete {
    button {
      background: #FF6060;
      color: white;
      cursor: pointer;

      &:hover {
        background: #df4b4b;
      }
    }
  }
}