@import "../../../index.scss";

.loginPage {
  .alert {
    margin-top: 0px;
  }

  .loginWrap {
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1150px) {
      display: block;
      max-width: 700px;
    }

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    .leftSide {
      width: calc(100% - 550px);

      @media (max-width: 1150px) {
        width: 100%;
        margin-bottom: 50px;
      }
    }

    .rightSide {
      background: #FFFFFF;
      box-shadow: 0 6px 20px 0 rgba(0,0,0,0.09);
      padding: 50px;
      border-radius: 20px;

      @media (max-width: 899px) {
        padding: 30px;
      }

      @media (max-width: 550px) {
        padding: 20px;
      }
    }
  }

  h1 {
    font-family: NewKansas-Medium;
    font-size: 32px;
    color: #293A56;

    @media (max-width: 550px) {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }

  .desc {
    font-family: VisueltPro-Regular;
    font-size: 17px;
    color: #394B5C;
    line-height: 28px;
    margin-top: 30px;
    
    a {
      color: #394B5C;
    }
  }

  ul {
    margin-top: 40px;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      font-family: VisueltPro-Regular;
      font-size: 17px;
      color: #394B5C;
      line-height: 28px;
      margin-top: 12px;

      img {
        display: block;
        margin-right: 10px;  
      }
    }
  }

  .familyPic {
    max-width: 300px;
    width: calc(100% - 70px);
    margin-bottom: 20px;
  }

  .loginPic {
    margin-bottom: 40px;
  }

  .trust {
    margin-top: 40px;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 3px 1fr;
    grid-column-gap: 15px;

    .left {
      background: #44656D;
      width: 3px;
      border-radius: 3px;
    }

    .right {
      p {
        font-family: VisueltPro-Medium;
        font-size: 17px;
        color: #44596D;
      }

      div {
        display: flex;
        align-items: center;
        margin-top: 15px;

        @media (max-width: 550px) {
          display: block;
        }

        img {
          display: block;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;

            @media (max-width: 550px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  form {
    width: calc(100%);
    min-width: 400px;
    max-width: 400px;

    @media (max-width: 899px) {
      min-width: 0;
    }

    .formError {
      font-family: VisueltPro-Regular;
      font-size: 16px;
      color: #FF6060;
      margin-top: -5px;
      display: block;
      margin-bottom: 25px;
    }

    .credentialsInput {
      margin-bottom: 20px;

      label {
        margin-bottom: 8px;
        display: block;
        font-family: VisueltPro-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 25px;
      }
    }

    input {
      background: #FFFFFF;
      border: 1px solid #D2D2D2;
      border-radius: 5px;
      display: block;
      font-family: VisueltPro-Regular;
      font-size: 17px;
      color: #000000;
      padding: 15px 15px;
      width: calc(100% - 32px);
    }

    .buttons {
      display: flex;
      align-items: center;
      margin-top: 30px;

      img {
        display: block;

        @media (max-width: 550px) {
          margin-top: 15px;
        }
      }

      @media (max-width: 550px) {
        display: block;
      }
    }

    button {
      @extend .buttonBasic;
      margin-right: 20px;
    }

    p {
      font-family: VisueltPro-Regular;
      font-size: 14px;
      color: #5C5C5C;
      margin-bottom: 13px;
      line-height: 20px;

      a {
        font-family: VisueltPro-Regular;
        font-size: 14px;
        color: #5C5C5C;
      }
    }

    .loginLink {
      color: #3F3F3F;
      text-align: left;
      margin-top: 20px;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0;

      a {
        white-space: nowrap;
        font-size: 16px;
        color: #3F3F3F;
      }
    }
  }
}

.passReset {
  margin: 0 auto;
  display: block;
  width: calc(100% - 50px);
  max-width: 400px;

  h1 {
    margin-bottom: 25px;
  }

  .login_form {
    p {
      color: #3F3F3F;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 30px;
    }
  }
  
}