.form-wishes {
  .form-title {
    color: #000000;
    margin-bottom: 25px;
  }

  p {
    font-family: VisueltPro-Regular;
    font-size: 17px;
    color: #5C5C5C;
    line-height: 25px;
    margin-top: 16px;
    margin-bottom: 30px;
  }
}

.form-wishes-place {
  h1 {
    margin-top: 40px !important;
  }

  .input_section-children {
    .buttons_section {
      background: none;
      padding: 0;
      border: 0;
      margin-top: 20px;
    }
  }

  p {
    a {
      color: #3a557c;
    }
  }

  textarea {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    width: calc(100% - 30px);
    resize: none;
    padding: 15px;
    width: calc(100% - 2px - 30px);
    font-family: VisueltPro-Regular;
    font-size: 17px;
    height: 200px;
    line-height: 25px;
    margin-top: 5px;
  }
}